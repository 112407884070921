import React from 'react';
import styled from 'styled-components';
import { useSurveySettingsContext } from '../SurveySettingsProvider';

const HeaderContainer = styled.div`
    text-align: center;
    background-color: var(--header-background-color, #f3f3f3);

    & img {
         width: 100%;
        max-width: 800px;
    }
`;

const SurveyTitle = styled.div`
    font-size: var(--survey-title-size, 1.35em);
    font-weight: var(--survey-title-weight, bold);
    margin-top: var(--survey-title-size, 1.35em);
    margin-bottom: var(--survey-title-size, 1.35em);
`;

const Header = () => {
    const { loading, surveySettingsJson, surveyHeaderImgSrc } = useSurveySettingsContext();
    const headerTitle = React.useMemo(() => surveySettingsJson?.title ?? 'Arena Survey', [surveySettingsJson]);

    if (loading) {
        return null;
    }

    return (
        <HeaderContainer>
            {surveyHeaderImgSrc && <img src={surveyHeaderImgSrc} alt="Header" title="Header" />}
            {!surveyHeaderImgSrc && <SurveyTitle>{headerTitle}</SurveyTitle>}
        </HeaderContainer>
    );
};

export default Header;