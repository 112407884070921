import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import SurveySettingsProvider from '../SurveySettingsProvider';
import Header from './Header';
import StyleProvider from '../StyleProvider';

const MainContainer = styled.div`
    flex: 1;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-color: #fff;
`;

const Layout = () => {
    return (
        <SurveySettingsProvider>
            {({ loading, themeJson }) => (
                <StyleProvider>
                    {!loading && themeJson && (
                        /* When themeJson has been added use layout from that */
                        <Outlet />
                    )}
                    {!loading && !themeJson && (
                        <>
                            <Header />
                            <MainContainer>
                                <Outlet />
                            </MainContainer>
                        </>
                    )}
                </StyleProvider>
            )}
        </SurveySettingsProvider>
    );
};

export default Layout;