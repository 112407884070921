import React from 'react';
import { PulseLoader } from 'react-spinners';
import styled from 'styled-components';

const LoadingContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const timeoutMs = 2000;

const Loading = () => {
    const [visible, setVisible] = React.useState(false);

    const timeoutId = React.useRef();

    React.useEffect(() => {
        timeoutId.current = setTimeout(() => {
            setVisible(true);
        }, timeoutMs);

        return () => clearTimeout(timeoutId.current);
    }, []);

    if (!visible) {
        return null;
    }

    return (
        <LoadingContainer>
            <PulseLoader
                color="#19b394"
                size={30}
            />
        </LoadingContainer>
    );
};

export default Loading;