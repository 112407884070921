import React from 'react';
import { useRoutes } from 'react-router-dom';
import Layout from './components/Layout';
import SurveyIdMissing from './components/SurveyIdMissing';
import SurveyComponent from './components/SurveyComponent';

const routes = [
    {
        path: '/',
        element: <Layout />,
        children: [
            { index: true, element: <SurveyIdMissing /> },
            {
                path: '/:surveyId',
                element: <SurveyComponent />
            }
        ],
    },
];

document.title = 'Loading Survey...';

const App = () => {
    const element = useRoutes(routes);
    return element;
};

export default App;
