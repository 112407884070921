import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSurveySettingsContext } from '../SurveySettingsProvider';

const surveyIdParameterName = 'id';

const SurveyIdMissing = () => {
    const { urlSearchParams } = useSurveySettingsContext();
    const navigate = useNavigate();

    React.useEffect(() => {
        const idParameterValue = urlSearchParams.get(surveyIdParameterName);

        if (idParameterValue && idParameterValue !== '') {
            urlSearchParams.delete(surveyIdParameterName);
            navigate(`/${idParameterValue}?${urlSearchParams.toString()}`);
        }
    }, [urlSearchParams, navigate]);

    return (
        <div>
            Missing SurveyId parameter
        </div>
    );
};

export default SurveyIdMissing;