import React from 'react';
import styled from 'styled-components';
import { useSurveySettingsContext } from '../SurveySettingsProvider';

const ErrorContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ErrorText = styled.div`

`;

const SurveyFetchingError = () => {
    const { status, statusText } = useSurveySettingsContext();

    const errorText = React.useMemo(() => {
        if (status && statusText) {
            return statusText;
        }
        return 'Error when fetching the survey';
    }, [status, statusText]);

    return (
        <ErrorContainer>
            <ErrorText>{errorText}</ErrorText>
        </ErrorContainer>
    );
};

export default SurveyFetchingError;