import React from 'react';
import 'survey-core/defaultV2.min.css';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import 'survey-core/survey.i18n';
import { useSurveySettingsContext } from '../SurveySettingsProvider';
import Loading from './Loading';
import SurveyFetchingError from './SurveyFetchingError';
import { API_ENDPOINT } from '../constants';
import Footer from './Footer';

const SurveyComponent = () => {
    const { loading, error, surveyId, token, surveyJson, themeJson, urlSearchParams } = useSurveySettingsContext();

    const onComplete = React.useCallback(async (survey, options) => {
        const plainSurveyData = survey.getPlainData();
        const request = {
            answers: plainSurveyData.reduce((data, { name, value }) => ({ ...data, [name]: value ?? null }), {})
        };

        options.showSaveInProgress();

        const queryString = urlSearchParams.toString();

        try {
            const resp = await fetch(`${API_ENDPOINT}/survey/answer/${surveyId}?${queryString}`, {
                method: 'POST',
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Survey-Token': token
                }
            });

            if (resp.ok) {
                options.showSaveSuccess();
            } else if (resp.status === 401) {
                options.showSaveError('Unauthorized');
            } else if (resp.status === 409) {
                options.showSaveError('Answer already submitted for this Survey');
            } else {
                options.showSaveError();
            }
        } catch (error) {
            options.showSaveError();
        }
    }, [surveyId, urlSearchParams, token]);

    const surveyModel = React.useMemo(() => {
        const model = new Model(surveyJson);

        if (themeJson) {
            model.applyTheme(themeJson);
        }

        model.onComplete.add(onComplete);
        model.locale = navigator.language;

        return model;
    }, [surveyJson, themeJson, onComplete]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return (
            <SurveyFetchingError />
        );
    }

    if (!themeJson) {
        // Add Footer if one exists and themeFile has not been added
        return (
            <>
                <Survey model={surveyModel} />
                <Footer />
            </>
        );
    }
    return (
        <Survey model={surveyModel} />
    );
};

export default SurveyComponent;