import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { API_ENDPOINT } from './constants';

const SurveySettingsContext = React.createContext();
const ContextProvider = SurveySettingsContext.Provider;

export const useSurveySettingsContext = () => {
    const context = React.useContext(SurveySettingsContext);
    if (context === undefined) {
        throw new Error('useSurveySettingsContext must be used within a SurveySettingsProvider');
    }
    return context;
};

const fetchAsJson = async (url) => {
    if (!url) {
        return {};
    }

    let status = 500;
    let statusText = null;
    let json = null;
    let errorJsonResponse = null;
    let errorTextResponse = null;

    try {
        const response = await fetch(url);

        if (response.ok && response.status === 200) {
            json = await response.json();
        } else {
            errorTextResponse = await response.text();
            try {
                errorJsonResponse = JSON.parse(errorTextResponse);
            } catch {
                //
            }
        }
        statusText = errorJsonResponse?.errorMessage ?? errorTextResponse ?? response.statusText;
        status = response.status;
    } catch {
        //
    }
    return { status, statusText, json };
};

const fetchAsBlob = async (url) => {
    if (!url) {
        return null;
    }

    let blob = null;
    try {
        const response = await fetch(url);

        if (response.ok && response.status === 200) {
            blob = await response.blob();
            return URL.createObjectURL(blob);
        }
    } catch {
        //
    }
    return null;
};

const SurveySettingsProvider = ({ children }) => {
    const { surveyId } = useParams();
    const location = useLocation();
    const urlSearchParams = React.useRef(new URLSearchParams(location.search)).current;
    const [surveyJson, setSurveyJson] = React.useState(null);
    const [themeJson, setThemeJson] = React.useState(null);
    const [surveySettingsJson, setSurveySettingsJson] = React.useState(null);
    const [surveyHeaderImgSrc, setSurveyHeaderImgSrc] = React.useState(null);
    const [loading, setLoading] = React.useState(Boolean(surveyId));
    const [status, setStatus] = React.useState(null);
    const [statusText, setStatusText] = React.useState(null);
    const [token, setToken] = React.useState(null);
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        const fetchSurveySettings = async () => {
            const { status, statusText, json } = await fetchAsJson(`${API_ENDPOINT}/survey/${surveyId}?${urlSearchParams.toString()}`);

            setStatus(status);
            setStatusText(statusText);

            if (json) {
                const filesToFetch = [];
                if (json?.token) {
                    setToken(json.token);
                }

                filesToFetch.push(fetchAsJson(json?.surveyJsFileUrl));
                filesToFetch.push(fetchAsJson(json?.settingsFileUrl));
                filesToFetch.push(fetchAsJson(json?.themeFileUrl));
                filesToFetch.push(fetchAsBlob(json?.headerImageUrl));

                const [{ json: surveyJson }, { json: surveySettingsJson }, { json: themeJson }, headerImageUrl] = await Promise.all(filesToFetch);

                setSurveyJson(surveyJson);
                setSurveySettingsJson(surveySettingsJson);
                setThemeJson(themeJson);
                setError(!surveyJson || !surveySettingsJson);
                setSurveyHeaderImgSrc(headerImageUrl);
            } else {
                setError(true);
            }
            setLoading(false);
        };
        if (surveyId) {
            setLoading(true);
            fetchSurveySettings();
        }
    }, [surveyId, urlSearchParams]);

    const contextValue = {
        surveyId,
        surveyJson,
        themeJson,
        urlSearchParams,
        surveySettingsJson,
        surveyHeaderImgSrc,
        loading,
        token,
        status,
        statusText,
        error
    };

    return (
        <ContextProvider value={contextValue}>
            {children(contextValue)}
        </ContextProvider>
    );
};

SurveySettingsProvider.propTypes = {
    children: PropTypes.any
};

export default SurveySettingsProvider;