import React from 'react';
import { useSurveySettingsContext } from './SurveySettingsProvider';

const defaultValues = {
    '--primary': '#19b394',
    '--background': '#ffffff',
    '--background-dim': '#f3f3f3',
    '--background-dim-light': '#f9f9f9',
    '--primary-foreground': '#ffffff',
    '--foreground': '#161616',
    '--base-unit': '8px',
    '--font-family': '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\', \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
    '--body-background-color': '#ffffff',
    '--header-background-color': '#ffffff',
    '--footer-background-color': '#f3f3f3',
    '--footer-text-color': '#000'
};

const setCssVariableIfDefined = (name, value) => {
    if (value) {
        document.documentElement.style.setProperty(name, value);
    }
};

Object.keys(defaultValues).forEach((variableName) => {
    setCssVariableIfDefined(variableName, defaultValues[variableName]);
});

const StyleProvider = ({ children }) => {
    const { loading, error, surveySettingsJson, themeJson } = useSurveySettingsContext();

    React.useEffect(() => {
        if (loading) {
            return () => { };
        }

        if (surveySettingsJson) {
            document.title = surveySettingsJson?.title ?? 'Arena Survey';

            if (surveySettingsJson?.font_link) {
                const styleElement = document.createElement('style');
                styleElement.append(`@import url('${surveySettingsJson?.font_link}');`);
                document.head.appendChild(styleElement);
            }

            if (!themeJson) {
                // These are only supported if themeFile has not been added
                setCssVariableIfDefined('--primary', surveySettingsJson?.survey_primary_color);
                setCssVariableIfDefined('--sjs-primary-backcolor-dark', surveySettingsJson?.survey_primary_backcolor);
                setCssVariableIfDefined('--sjs-general-forecolor', surveySettingsJson?.survey_font_color);
                setCssVariableIfDefined('--background', surveySettingsJson?.survey_background_color);
                setCssVariableIfDefined('--font-family', surveySettingsJson?.font_family);
                setCssVariableIfDefined('--header-background-color', surveySettingsJson?.header_background_color);
                setCssVariableIfDefined('--background-dim', surveySettingsJson?.main_container_background_color);
                setCssVariableIfDefined('--body-background-color', surveySettingsJson?.body_background_color);
                setCssVariableIfDefined('--footer-text-color', surveySettingsJson?.footer_text_color);
                setCssVariableIfDefined('--footer-background-color', surveySettingsJson?.footer_background_color);

                if (surveySettingsJson?.body_background_image) {
                    document.body.style.setProperty('background-image', `url(${surveySettingsJson?.body_background_image})`);
                }
            }
        } else if (!loading || error) {
            document.title = 'Arena Survey';
        }
        return () => { };
    }, [surveySettingsJson, loading, error]);

    return children;
};

export default StyleProvider;